import Head from 'next/head'
import Button from '@/components/base/Button'
import styles from '@/styles/Error.module.css'

const ErrorPage = () => {
    return (
        <>
            <Head>
                <title>
                    {`404 Not Found | ${process.env.NEXT_PUBLIC_APP_NAME}`}
                </title>
            </Head>

            <div className={styles.errorPage}>
                <div className={styles.inner}>
                    {/* <div className={styles.videoLogo}>
                        <div className="w-[65px] mobile:w-[40px]">
                            <Image
                                src="/logo.svg"
                                alt={process.env.NEXT_PUBLIC_APP_NAME}
                                layout="responsive"
                                width={500}
                                height={500}
                            />
                        </div>
                    </div>*/}
                    <h1 className={styles.heading}>OOPS!</h1>
                    <h2 className={styles.subHeading}>
                        We looked everywhere and couldn’t find that page.
                    </h2>
                    <p className={styles.description}>
                        This might be because: You have typed the web address
                        incorrectly, or the page you were looking for may have
                        been moved, updated or deleted.
                    </p>
                    <div className="mt-6">
                        <Button
                            text="Back to Home"
                            type="button"
                            size="md"
                            variant="primary"
                            link="/"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

ErrorPage.error = true
export default ErrorPage
